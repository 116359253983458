<template>
  <div class="lightestGreyBackground" :class="{'vasion-calc-height': !isFromMobile, 'mobile-container': isFromMobile }">
    <div v-if="!isFromMobile" class="form-header">
      <div class="vasion-page-title header-text">
        {{ formName }}
      </div>
      <div class="btns-container">
        <VasionButton
          v-if="canAccessAllForms"
          id="cancel"
          name="cancel"
          :classProp="'text'"
          class="button-right"
          @vasionButtonClicked="backToMyForms"
        >
          Back to My {{ $layoutsLabelPlural }}
        </VasionButton>
        <VasionButton
          id="btnFullWidth"
          name="btnFullWidth"
          class="full-width-btn"
          :title="`Turn Full Width ${fullWidthWrapperEnabled ? 'Off' : 'On'}`"
          :icon="fullWidthWrapperEnabled ? 'VasionExitFullscreenIcon' : 'VasionFullscreenIcon'"
          @vasionButtonClicked="toggleFullwidhtContainer"
        />
      </div>
    </div>
    <div :class="{'form-container': !isFromMobile, 'full-width': isFromMobile, 'full-height': isFromMobile}">
      <div
        v-if="isAuthed"
        :class="{ 'full-width': fullWidthWrapperEnabled, 'form-wrapper' : true, 'from-wrapper-mobile-link' : isFromMobile }"
      >
        <StartWorkflowManager
          :viewMode="true"
          @closeStartWorkflow="showStartWorkflowManager = false"
        />
      </div>
    </div>
    <VasionGeneralModal
      id="cancel-confirmation-modal"
      :confirmButtonText="'YES'"
      :rejectButtonText="'NO'"
      :modalType="'message'"
      :buttonGroup="'center'"
      message="Are your sure you want to leave without saving or submitting the form?"
      :sync="showDirtyFormModal"
      @confirmButtonClick="dirtyFormModalConfirmation()"
      @noButtonClick="dirtyFormModalCancel()"
    />
  </div>
</template>

<script>
/* eslint-disable global-require */
import StartWorkflowManager from '@/components/workflow/StartWorkflowManager.vue'

export default {
  name: 'MyForm',
  components: {
    StartWorkflowManager,
  },
  async beforeRouteLeave(to, from, next) {
    if (this.$store.state.forms.formIsDirty) {
      this.routeNext = next
      await this.$store.dispatch('forms/setDirtyFormModalShowing', true)
      this.showDirtyFormModal = true
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      fullWidthWrapperEnabled: false,
      isAuthed: null,
      showDirtyFormModal: false,
    }
  },
  computed: {
    canAccessAllForms() { return this.$store.getters['common/canAccessFormsApp_RolesOnly'] },
    formName() {
      if (this.isAuthed === null) {
        return ''
      } else if (this.isAuthed === true) {
        if (this.$store.state.forms.formDrafName != '') {
          return this.$store.state.forms.formDrafName
        }
        return this.$route.params.formName
      }

      return 'Invalid Permissions to View the Form'
    },
    isFromMobile() { return this.$route.query.isFromMobile ? this.$route.query.isFromMobile : false },
    isFormDraft() { return this.$route.params?.draftId }
  },
  async created() {
    const payload = {
      StartNumber: 1,
      MaxReturnCount: 10000,
      IncludeUserSecurity: false,
      IncludeFields: false,
      PopulateOnlyNameAndID: true,
    }
    this.$store.dispatch('attributeForm/getMyForms', payload)
    const formPayload = {
      formId: this.$route.params.formID,
      workflowId: this.$route.params.workflowID
    }
    this.isAuthed = !this.$route.params.formID ? false : await this.$store.dispatch('forms/checkIfUserCanAccessEFormAndWorkflow', formPayload)
    if (!this.isAuthed) {
      this.$store.dispatch('common/suppressDefaultApp', false)
      this.$store.dispatch('mainViews/toggleVasionHome', true)
      this.$router.push({ name: 'TheVasionHome' })
      return
    }
    if (!this.isFormDraft) this.$store.dispatch('mainViews/changeMainNav', `my-form-${this.$route.params.formID}`)
    this.$store.dispatch('attributeForm/setIsForm', true)
  },
  methods: {
    backToMyForms() { this.$router.push({ name: 'MyForms' }) },
    async dirtyFormModalConfirmation() {
      await this.$store.dispatch('forms/setDirtyFormModalShowing', false)
      this.showDirtyFormModal = false
      this.routeNext()
    },
    async dirtyFormModalCancel() {
      await this.$store.dispatch('forms/setDirtyFormModalShowing', false)
      this.showDirtyFormModal = false
    },
    toggleFullwidhtContainer() {
      this.fullWidthWrapperEnabled = !this.fullWidthWrapperEnabled
      this.$root.$emit('update-zoom', this.fullWidthWrapperEnabled)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.lightestGreyBackground {
  background-color: $grey-75;
  display: flex;
  flex-direction: column;
  align-items: center;
}
// xs
.form-header {
  display: block;
  height: 96px;
  width: 100%;
  box-shadow: 0 1px 0 0 $grey-100;
  background-color: $white;
  align-items: center;
  justify-content: space-between;
}
.vasion-page-title {
  margin: 6px 16px 0 16px;
  font-size: 18px;
  height: 40px;
}
.btns-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.full-width-btn {
  display: none ;
}
.form-container {
  width: 100%;
  height: calc(100vh - 162px);
  padding: 1rem;
}
.form-wrapper {
  border-radius: 0.5rem;
  box-shadow: 0 .125rem .25rem rgba(33,37,41,.075);
  background-color: $white;
  overflow-y: auto;
  max-height: calc(100vh - 172px);
  width: 100%;
}
.full-width {
  width: 100% !important;
}
.full-height {
  height: 100% !important;
}
.mobile-container {
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  padding: 8px;
}
::v-deep .formio-component-textarea.hide-textarea,
::v-deep .formio-component-textarea.hide-textarea textarea {
  z-index: -1;
}
.mobile-container ::v-deep .formio-component-datetime input.form-control:not([disabled]) {
  background-color: #ffffff !important;
}
// sm: 576px
@media (min-width: $breakpoint-sm) {
  .form-header {
    height: 90px;
  }
  .form-wrapper {
    max-height: calc(100vh - 166px);
  }
}
// md: 768px
@media (min-width: $breakpoint-md) {
  .form-header {
    display: flex;
    height: 74px;
  }
  .vasion-page-title {
    margin: 0 0 0 16px;
    font-size: 20px;
    height: unset;
  }
  .full-width-btn {
    display: block;
    margin-right: 16px;
  }
  .form-container {
    height: calc(100vh - 162px);
    display: flex;
    justify-content: center;
  }
  .form-wrapper {
    max-height: calc(100vh - 150px);
    width: 736px;
  }
}
// prevent side bar
@media (min-width: 950px) and (max-width: 991.98px) {
  .form-header {
    height: 76px;
  }
  .vasion-page-title {
    font-size: 22px;
  }
  .form-container {
    height: calc(100vh - 162px);
    display: flex;
    justify-content: center;
  }
  .form-wrapper {
    width: 918px - 256px;
  }
}
// lg: 992px
@media (min-width: $breakpoint-lg) {
  .form-header {
    height: 76px;
  }
  .vasion-page-title {
    font-size: 22px;
  }
  .form-container {
    height: calc(100vh - 162px);
    display: flex;
    justify-content: center;
  }
  .form-wrapper {
    width: 960px - 256px;
  }
}
// xl: 1200px
@media (min-width: $breakpoint-xl) {
  .form-header {
    height: 78px;
  }
  .vasion-page-title {
    font-size: 24px;
  }
  .form-container {
    height: calc(100vh - 164px);
  }
  .form-wrapper {
    width: 1168px - 256px;
  }
}
// xxl: 1400px
@media (min-width: $breakpoint-xxl) { 
  .form-wrapper {
    width: 1368px - 256px;
  }
}
.from-wrapper-mobile-link {
  height: calc(100vh - 16px);
  height: calc(100dvh - 16px);
  max-height: calc(100vh - 16px);
  max-height: calc(100dvh - 16px);
  max-width: 100vw;
  margin: auto;
}
</style>
